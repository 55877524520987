<template>
  <div class="h-100" style="overflow-y:auto">
    <div class="container mt-3 mt-lg-5">
      <div class="row">
        <div :class="{ 'col-md-12': !isSearch, 'col-md-8': isSearch }">
          <div class="card" id="box">
            <div class="card-header d-flex justify-content-between" id="box_title">
              <div>
                <h5>Słowniki</h5>
                <div class="spinner-border spinner-border-sm text-primary" v-if="isLoading"></div>
              </div>
              <div class="card-header-right" id="box_title_buttons">
                <router-link class="btn btn-outline-primary me-1" to="/dictionary/create">
                  Utwórz
                </router-link>
                <button @click="isSearch = !isSearch" class="btn p-2 btn-outline-secondary me-1">
                  <span class="material-icons-outlined"> search </span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive mb-3">
                <table class="table table-hover table-index">
                  <thead>
                    <tr>
                      <th>
                        {{ lang("lp") }}
                      </th>
                      <th>Nazwa cechy</th>
                      <th>Label dla moderatora</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td>
                        {{ startcount + i }}
                      </td>
                      <td>
                        {{ item.name }}
                      </td>
                      <td>
                        {{ item.label }}
                      </td>
                      <td class="text-end">
                        <router-link :title="lang('edit')" :to="'/dictionary/' + item.name + '/items'"
                          class="btn btn-outline-secondary btn-icon-sm">
                          <i class="material-icons-outlined">folder</i>
                        </router-link>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ul class="pagination justify-content-center">
                <li @click.prevent="clickPage(0)">
                  <a class="page-link">
                    {{ lang("first") }}
                  </a>
                </li>
                <li :class="{ active: page == index }" @click.prevent="clickPage(index)" class="page-item"
                  v-for="index in pages" :key="index">
                  <a class="page-link" href="#" v-if="
                    page != index &&
                    ((index > Number(page) - 5 && index <= Number(page)) ||
                      (index < Number(page) + 5 && index >= Number(page)))
                  ">
                    {{ index }}
                  </a>
                  <span class="page-link" v-if="page == index">
                    {{ index }}
                  </span>
                </li>
                <li @click.prevent="clickPage(pages)">
                  <a class="page-link">
                    {{ lang("last") }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-show="isSearch">
          <div class="card" id="box">
            <div class="card-header" id="box_title">
              <h5>
                {{ lang("filter") }}
              </h5>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="col-form-label">
                  {{ lang("name") }}
                </label>
                <input class="form-control" name="search_name" type="text" v-model="search.name" />
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label"> </label>
                <button @click="clickSearch" class="btn btn-outline-secondary me-1">
                  {{ lang("search") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../../api/axios';

export default {
  template: "#shop_dictionary_index-template",
  components: {},
  data: function () {
    return {
      isLoading: true,
      isSearch: false,
      items: [],
      page: 1,
      count: 0,
      count_all: 0,
      pages: 0,
      startcount: 1,
      limit: 50,
      search: {
        name: "",
        status: "",
      },
    };
  },
  created: function () { },
  mounted: function () {
    console.log("STORAGE", this.$storage);
    //this.page = this.$storage.shop.dictionary.page;
    //this.search = this.$storage.shop.dictionary.search;
    this.getPosts();
  },
  computed: {},
  methods: {
    lang: function (key) {
      return key;
    },
    clickSearch() {
      this.$storage.shop.dictionary.search = this.search;
      this.getPosts();
    },
    clickPage: function (nr) {
      this.$storage.shop.dictionary.page = nr;
      this.page = nr;
      this.getPosts();
    },
    getPosts: function () {
      // var t = this;
      this.isLoading = true;
      this.search.limit = this.limit;
      (this.search.page = this.page),
        axios
          .get("api/dictionary", {
            dictionarys: this.search,
          })
          .then((response) => {
            this.items = response.data.data;
            this.page = response.data.page;
            this.count = response.data.count;
            this.count_all = response.data.count_all;
            this.pages = response.data.pages;
            //this.startcount = (this.page - 1) * this.limit + 1;
            this.acl = response.data.acl;
            this.isLoading = false;
          })
          .catch((error) => {
            //var msg = error.response.statusText;
            if (error.response.status === 403) {
              // msg = "Brak uprawnień";
            }
            //MAGICO_PANEL.alert('error', msg);
          });
    },
  },
};
</script>
